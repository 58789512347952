import { fetchWrapper, objectToQueryParams } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'admin/orders';
const visitsBaseUrl = process.env.REACT_APP_BASE_API + 'ordervisits';

export async function create(form) {
  return await fetchWrapper.post(baseUrl, form);
}

export async function findAll(filters) {
  const url = `${baseUrl}?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function findHistory(filters) {
  const url = `${baseUrl}/executions/history?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function uploadPhoto(form) {
  const url = `${process.env.REACT_APP_BASE_API}cleaners/orders/executions/photo`;
  return await fetchWrapper.post(url, form);
}

export async function update(form) {
  return await fetchWrapper.patch(baseUrl, form);
}

export async function destroy(userId) {
  return await fetchWrapper.delete(baseUrl, { id: userId });
}

export async function download(filters) {
  const url = `${baseUrl}/executions/history/report?${objectToQueryParams(filters)}`;
  return await fetchWrapper.get(url);
}

export async function getOrderTemplates(form) {
  return await fetchWrapper.post(baseUrl+'/templates', form);
}

export async function createOrderTemplate(form) {
  return await fetchWrapper.post(baseUrl+'/templates/create', form);
}

export async function editOrderTemplate(form) {
  return await fetchWrapper.post(baseUrl+'/templates/edit', form);
}

export async function deleteOrderTemplate(form) {
  return await fetchWrapper.post(baseUrl+'/templates/delete', form);
}

export async function getOrderVisits(form) {
  return await fetchWrapper.post(visitsBaseUrl+'/get', form);
}