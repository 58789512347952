import { useContext } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import { IconChevronDown, IconHome, IconListCheck, IconMenu2, IconBuildingStore, IconUsers, IconCalendar } from '@tabler/icons-react';
import { AuthContext } from '../context';
import { Logo } from '../assets/images';
import { ReportIcon, CaretBottom, CaretTop, MonitorIcon, PayrollIcon } from '../assets/icons';

const navigation = [
  { name: 'Inicio', href: '/', icon: IconHome },
  { name: 'Usuarios', href: '/users', icon: IconUsers },
  { name: 'Tiendas', href: '/stores', icon: IconBuildingStore },
  { name: 'Trabajo realizado', href: '/history', icon: IconListCheck },
  { name: 'Monitor de Limpieza', href: '/monitor', icon: MonitorIcon, iconImage: true },
  { name: 'Payroll', href: '/payroll', icon: PayrollIcon, iconImage: true, submenu: [
    { name: 'Cálculo de nómina', href: '/payroll/calculation' },
    { name: 'Registro de pagos', href: '/payroll/payments' }
  ] },
  { name: 'Reportes', href: '/reports', icon: ReportIcon, iconImage: true, submenu: [
    { name: 'Desempeño', href: '/reports/work' },
    // { name: 'Pagos', href: '/reports/payments' }
  ] },
  { name: 'Plantillas Horarios', href: '/orderTemplates', icon: IconCalendar },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const toggleSidebar = (only_open = false) => {
  const items = document.getElementsByClassName('desk-sidebar');
  for (let key = 0; key < items.length; key++) {
    if (!only_open || Object.values(items.item(key).classList).indexOf('open') === -1) {
      items.item(key).classList.toggle('open');
    }
  }
}

const Layout = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  let routeName = navigation.find(x => x.href === location.pathname || x.submenu?.find(i => i.href === location.pathname));

  if (routeName?.submenu) {
    const item = routeName.submenu.find(i => i.href === location.pathname);

    if (item) {
      routeName = item;
    }
  }

  return (
    <div id="layout">
      <div className="desk-sidebar desk-sidebar__header">
        <div className="sidebar-logo">
          <div className="sidebar-logo__wrapper">
            <img src={Logo} alt={process.env.REACT_APP_NAME} />
          </div>
        </div>
        <div className="desk-headerbar">
          <button type="button" className="-mr-2.5 p-2.5 text-gray-700" onClick={ () => toggleSidebar() }>
            <span className="sr-only">Open sidebar</span>
            <IconMenu2 />
          </button>

          <div className="h-6 w-px bg-gray-200" aria-hidden="true" />

          <span className="font-medium text-lg">{ routeName?.name }</span>

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <div className="relative flex flex-1" />
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

              <div className="relative">
                <Dropdown placement="bottom-start">
                  <DropdownTrigger>
                    <div className="-m-1.5 flex items-center p-1.5">
                      <img
                        className="h-8 w-8 rounded-full bg-gray-50"
                        src={Logo}
                        alt={user?.person?.fullName}
                      />
                      <span className="hidden lg:flex lg:items-center">
                        <span className="ml-4 font-semibold leading-6 text-gray-900" aria-hidden="true">
                          { user?.person?.fullName }
                        </span>
                        <IconChevronDown className="ml-2 text-gray-400" size={18} />
                      </span>
                    </div>
                  </DropdownTrigger>

                  <DropdownMenu aria-label="User Actions" variant="flat">
                    <DropdownItem key="logout" color="danger" textValue="Cerrar sesión">
                      <Link to="/logout">Cerrar sesión</Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SideBar />

      <main>
        {!!user && <Outlet />}
      </main>
    </div>
  )
}

const SideBar = () => {
  const location = useLocation();

  return (
    <div className="desk-sidebar">
      <aside>
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col -mx-2 space-y-1">
              {navigation.map((item, index) => {
                const isActive = location.pathname === item.href || item.submenu?.find(i => i.href === location.pathname) != null;

                return (
                  <div key={`menu-${index}`}>
                    <li className="menu-item" title={item.name}>
                      <Link
                        to={item.href}
                        onClick={ () => {
                          if (item.submenu?.length > 0) {
                            toggleSidebar(true);
                          }
                        } }
                        className={classNames(
                          isActive && !item.submenu
                            ? 'bg-gray-50 text-primary active'
                            : 'text-gray-700 hover:text-primary hover:bg-gray-50',
                          'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'
                        )}
                      >
                        {
                          item.iconImage ? (
                            <img src={ item.icon } className={ `icon-menu ${ isActive ? 'active' : '' }` } alt="" />
                          ) : (
                            <item.icon className={classNames('group-hover:text-primary aspect-square shrink-0', isActive ? 'text-primaryDark':'text-gray-500')} />
                          )
                        }
                        <span>{item.name}</span>
                        {
                          item.submenu?.length > 0 && (
                            <img src={ isActive ? CaretTop : CaretBottom } className="caret" alt="" />
                          )
                        }
                      </Link>
                    </li>
                    {
                      isActive && item.submenu?.map((item, subIdx) => {
                        const isActive = location.pathname === item.href;

                        return (
                          <li key={`submenu-${subIdx}`} className="menu-item submenu-item" title={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                isActive
                                  ? 'text-primary'
                                  : 'text-gray-700 hover:text-primary hover:bg-gray-50',
                                'group flex gap-x-3 rounded-md p-2 leading-6 font-semibold'
                              )}
                            >
                              <span>{item.name}</span>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </div>
                )
              })}
            </ul>

          </nav>
        </div>
      </aside>
    </div>
  )
}

export default Layout;