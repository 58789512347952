/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconCalendarMonth, IconClock, IconEdit, IconEye, IconMapPin, IconPlus, IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { classNames, Constants, getClockTotalTime } from '../../utils';
import { OrdersService } from '../../services';
import ModalCreate from './create-order';

const statuses = [
  { label: 'Todos', value: '' },
  { label: 'No iniciada', value: Constants.ORDER.STATUS.PENDING },
  { label: 'Iniciada', value: Constants.ORDER.STATUS.INITIATED },
  { label: 'Finalizada', value: Constants.ORDER.STATUS.FINISHED },
  // { label: 'Cancelada', value: Constants.ORDER.STATUS.CANCELLED },
];

const getStatusColor = (status) => {
  switch (status) {
    case Constants.ORDER.STATUS.PENDING:
      return 'orange';

    case Constants.ORDER.STATUS.INITIATED:
      return 'green';

    default:
      return 'black';
  }
}

const MODAL_ACTION = {
  NONE: 0,
  VIEW: 1,
  EDIT: 2,
  CREATE: 3,
  VIEW_SUPER: 4,
  EDIT_SUPER: 5,
  CREATE_SUPER: 6,
}

const Orders = () => {

  const [selectedOrder, setSelectedOrder] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { canResetFilter, orders, filters, filterBy, goToPage, isLoading, pagination, reload } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedOrder(order);
    setModalAction(action);
  }

  const closeModal = (reloading = false) => {
    setSelectedOrder(null);
    setModalAction(MODAL_ACTION.NONE);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal
        size="sm"
        isOpen={!!selectedOrder || modalAction === MODAL_ACTION.CREATE}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => {
            if (modalAction === MODAL_ACTION.VIEW) return (
              <ModalView
                order={selectedOrder}
                onClose={onClose}
                onEdit={() => setModalAction(MODAL_ACTION.EDIT)}
              />
            );
            if (modalAction === MODAL_ACTION.VIEW_SUPER) return (
              <ModalViewSuper
                order={selectedOrder}
                onClose={onClose}
                onEdit={() => setModalAction(MODAL_ACTION.EDIT_SUPER)}
              />
            );
            if (modalAction === MODAL_ACTION.CREATE) return (
              <ModalCreate onClose={onClose} onSuccess={() => closeModal(true)} />
            );
            if (modalAction === MODAL_ACTION.EDIT) return (
              <ModalCreate
                order={selectedOrder}
                onClose={onClose}
                onSuccess={() => closeModal(true)}
              />
            );
          }}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <div className="flex gap-1 text-white font-semibold">
        <div
          className={classNames('ml-4 px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.CLEANER ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.CLEANER, 'level_id')}
        >
          <span>Limpiadores</span>
        </div>

        <div
          className={classNames('px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.SUPERVISOR ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.SUPERVISOR, 'level_id')}
        >
          <span>Supervisores</span>
        </div>
      </div>

      {filters.level_id === Constants.LEVELS.CLEANER ? (
        <Table aria-label="Ordenes registradas (Limpiadores)"
          topContent={
            <div className="flex flex-row justify-between items-center gap-4">
              <h3 className="text-xl font-medium text-primaryDark">Ordenes registradas</h3>
              <Button
                color="primary"
                className="pl-2"
                startContent={<IconPlus color="white" />}
                onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
              >
                Nueva
              </Button>
            </div>
          }
          topContentPlacement="inside"
        >
          <TableHeader>
            <TableColumn>Asignada</TableColumn>
            <TableColumn>Número</TableColumn>
            <TableColumn>Trabajador</TableColumn>
            <TableColumn>Fecha</TableColumn>
            <TableColumn>Estatus</TableColumn>
            <TableColumn>Dirección</TableColumn>
            <TableColumn align="end" />
          </TableHeader>
          <TableBody items={orders}>
            {(order) => {
              const cleaners = order?.order_cleaners?.map(x => {
                const isToday = x.executions[0]?.date === moment().format('YYYY-MM-DD');
                return {
                  name: x?.cleaner?.person?.fullName,
                  status: x.executions[0]?.status,
                  statusText: x.executions[0]?.statusText,
                  statusColor: isToday ? getStatusColor(x.executions[0]?.status) : 'black',
                  date: x?.executions[0]?.date,
                }
              });

              return (
                <TableRow key={order.id}>
                  <TableCell>
                    { order?.store?.name }
                  </TableCell>
                  <TableCell className="text-primary cursor-pointer">
                    <span onClick={() => onSelectItem(order, MODAL_ACTION.VIEW)}>
                      { order?.id }
                    </span>
                  </TableCell>
                  <TableCell>
                    { cleaners.map(x => x.name)?.join(', ') }
                  </TableCell>
                  <TableCell>
                    { moment(cleaners[0]?.date).format('DD/MM/YYYY') }
                  </TableCell>
                  <TableCell>
                    {cleaners.map((x, index) => (
                      <div key={index}>
                        <span className={`text-${x.statusColor} `}>
                          { x.statusText }
                        </span>
                        { (index + 1) < cleaners.length ? ', ':'' }
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    { order.store?.address ?? '' }
                    {/* <IconMapPin className="self-center" onClick={() => onSelectItem(order, MODAL_ACTION.VIEW)} /> */}
                  </TableCell>
                  <TableCell align="right">
                    <div className="relative flex justify-end items-center gap-2">
                      <Tooltip content="Ver detalles">
                        <IconEye onClick={() => onSelectItem(order, MODAL_ACTION.VIEW)} />
                      </Tooltip>
                      <Tooltip content="Editar">
                        <IconEdit onClick={() => onSelectItem(order, MODAL_ACTION.EDIT)} />
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              )
            }}
          </TableBody>
        </Table>
      ) : (
        <Table aria-label="Ordenes registradas (Supervisores)"
          topContent={
            <div className="flex flex-row justify-between items-center gap-4">
              <h3 className="text-xl font-medium text-primaryDark">Ordenes registradas</h3>
              {/* <Button
                color="primary"
                className="pl-2"
                startContent={<IconPlus color="white" />}
                onClick={() => onSelectItem(null, MODAL_ACTION.CREATE)}
              >
                Nueva
              </Button> */}
            </div>
          }
          topContentPlacement="inside"
        >
          <TableHeader>
            <TableColumn>Asignada</TableColumn>
            <TableColumn>Número</TableColumn>
            <TableColumn>Supervisor</TableColumn>
            <TableColumn>Fecha</TableColumn>
            <TableColumn>Estatus</TableColumn>
            <TableColumn>Dirección</TableColumn>
            <TableColumn align="end" />
          </TableHeader>
          <TableBody items={orders}>
            {(order) => {
              return (
                <TableRow key={order.id}>
                  <TableCell>{ order?.store?.name }</TableCell>
                  <TableCell className="text-primary cursor-pointer">
                    <span onClick={() => onSelectItem(order, MODAL_ACTION.VIEW_SUPER)}>
                      { order?.id }
                    </span>
                  </TableCell>
                  <TableCell>{ order?.user?.person?.name }</TableCell>
                  <TableCell>{ moment(order?.date).format('DD/MM/YYYY') }</TableCell>
                  <TableCell>{ order.status_text }</TableCell>
                  <TableCell>
                    { order.store?.address ?? '' }
                  </TableCell>
                  <TableCell align="right">
                    <div className="relative flex justify-end items-center gap-2">
                      {/* <Tooltip content="Ver detalles">
                        <IconEye onClick={() => onSelectItem(order, MODAL_ACTION.VIEW)} />
                      </Tooltip>
                      <Tooltip content="Editar">
                        <IconEdit onClick={() => onSelectItem(order, MODAL_ACTION.EDIT)} />
                      </Tooltip> */}
                    </div>
                  </TableCell>
                </TableRow>
              )
            }}
          </TableBody>
        </Table>
      )}

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {

  const initialFilter = {
    search: '',
    since: '',
    until: '',
    execution_date: '',
    status: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[14rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        placeholder="Nombre"
        startContent={<IconSearch />}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      {/* <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        min={new Date().toISOString().split('T')[0]}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full lg:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        min={new Date().toISOString().split('T')[0]}
      /> */}
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.execution_date ? '[]':'foreground-400'}`,
        }}
        label="Fecha"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.execution_date}
        onValueChange={v => onChange(v, 'execution_date')}
        min={new Date().toISOString().split('T')[0]}
      />
      <Select
        label="Estatus"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[9rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={[form.status]}
        onSelectionChange={v => onChange(v.currentKey, 'status')}
      >
        {statuses.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ order, onClose }) => {
  const cleaners = order?.order_cleaners?.map(x => x?.cleaner?.person?.fullName)?.join(', ');
  let minTime = 0;

  if (order?.store?.payment_type === Constants.STORE.PAYMENT_TYPE.PER_HOUR && order?.store?.min_hour > 0) {
    minTime = order?.store?.min_hour;
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div>{ order?.store?.name }</div>
        <div className="text-success">N° { order?.store?.number }</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full">
          <div className="flex text-sm items-center gap-2 text-primary">
            <IconMapPin size={20} />
            <p>Dirección</p>
          </div>
          <p className="text-sm">{ order?.store?.address }</p>

          <p className="font-medium text-red mt-2">Fecha y hora programada</p>
          <div className="flex items-center gap-4 text-sm">
            <div className="flex items-center gap-2">
              <IconCalendarMonth stroke={1.5} size={20} />
              <p>{ moment(order.starts_at).format('DD MMMM YYYY') }</p>
            </div>
            <div className="flex items-center gap-2">
              <IconClock stroke={1.5} size={20} />
              <span>{ moment(order.time_starts_at, 'HH:mm:ss').format('hh:mm a') }</span>
              <span> - </span>
              <span>{ moment(order.time_ends_at, 'HH:mm:ss').format('hh:mm a') }</span>
            </div>
          </div>

          {!!minTime && (
            <div className="flex flex-col">
              <p className="font-medium text-red mt-2">Horas mínimas requeridas</p>
              <div className="flex items-center gap-4 text-sm">
                <div className="flex items-center gap-2">
                  <IconClock stroke={1.5} size={20} />
                  <span>{ getClockTotalTime(minTime) } Horas</span>
                </div>
              </div>
            </div>
          )}

          <p className="text-sm font-medium text-blue-800 mt-4">
            Prioridad: { order?.priority }
          </p>
          <p className="text-sm font-medium text-blue-800 mt-2">
            Trabajador asignado: { cleaners }
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalViewSuper = ({ order, onClose }) => {
  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div>{ order?.store?.name }</div>
        <div className="text-success">N° { order?.store?.number }</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full">
          <div className="flex text-sm items-center gap-2 text-primary">
            <IconMapPin size={20} />
            <p>Dirección</p>
          </div>
          <p className="text-sm">{ order?.store?.address }</p>

          <p className="font-medium text-red mt-2">Fecha programada</p>
          <div className="flex items-center gap-4 text-sm">
            <div className="flex items-center gap-2">
              <IconCalendarMonth stroke={1.5} size={20} />
              <p>{ moment(order.starts_at).format('DD MMMM YYYY') }</p>
            </div>
          </div>

          <p className="text-sm font-medium text-blue-800 mt-4">
            Prioridad: { order?.observations }
          </p>
          <p className="text-sm font-medium text-blue-800 mt-2">
            Supervisor asignado: { order?.user?.person?.name }
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </div>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    since: '',
    until: '',
    status: '',
    level_id: Constants.LEVELS.CLEANER,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
    isFirstPage: true,
    isLastPage: true,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState({ ...initialFilters });
  const [pagination, setPagination] = useState({ ...initialPagination });

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      if (filters.level_id === Constants.LEVELS.CLEANER) {
        const response = await OrdersService.findAll(filters)
        const { data, ...rest } = response;

        setData(data);
        setPagination(rest);
        setCanFetch(true);

      } else {
        const form = {
          ...filters,
          since: moment(filters.execution_date ?? undefined).format('YYYY-MM-DD'),
          until: moment(filters.execution_date ?? undefined).format('YYYY-MM-DD'),
        };

        const response = await OrdersService.getOrderVisits(form)
        const { data, ...rest } = response;

        setData(data);
        setPagination(rest);
        setCanFetch(true);
      }


    } catch (error) {
      setData([]);
      onError(String(error));
      setPagination(initialPagination);
      setCanFetch(true);
    }
  }

  const deleteItem = async (id) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.destroy(id);
      toast.success('Orden eliminada con éxito');

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const updateItem = async (order) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      await OrdersService.update(order);
      toast.success('Orden actualizada con éxito');
      fetchData();

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentFilter = () => {
    // Truco para obtener el estado actualizado (filters es mantenida con el estado actual por el componente Pagination)
    let f;
    setFilters(s => {
      f = s;
      return s;
    });
    return f;
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const filter = getCurrentFilter();
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filter, page });
    }
  }

  const changePerPage = (perPage) => {
    const filter = getCurrentFilter();
    setCanFetch(true);
    setFilters({ ...filter, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      const filter = getCurrentFilter();
      setFilters({ ...filter, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    deleteOrder: deleteItem,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    orders: data,
    pagination,
    reload,
    updateOrder: updateItem,
  }
}

export default Orders;
