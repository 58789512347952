/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Button, Chip, Divider, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, Pagination, Select, SelectItem, Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, Tooltip } from '@nextui-org/react';
import { IconArrowDown, IconCalendarMonth, IconClock, IconEye, IconMapPin, IconSearch, IconX } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { IconExport } from '../../assets/icons';
import { Constants, fromPhotos, getTotalTime } from '../../utils';
import { OrdersService } from '../../services';

const google = window.google;

const statuses = [
  { label: 'No iniciada', value: Constants.ORDER.STATUS.PENDING },
  { label: 'No finalizada', value: Constants.ORDER.STATUS.INITIATED },
  { label: 'Finalizada', value: Constants.ORDER.STATUS.FINISHED },
  // { label: 'Cancelada', value: Constants.ORDER.STATUS.CANCELLED },
];

const turns = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' },
];

const History = () => {

  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const { canResetFilter, orders, download, filterBy, goToPage, isLoading, pagination, reload } = useFetchTable();

  const closeModal = (reloading = false) => {
    setSelectedOrder(null);
    if (reloading) reload();
  }

  return (
    <>
      {isLoading && (
        <div className="w-screen h-screen fixed inset-0 z-[70] flex justify-center items-center bg-white/30">
          <Spinner />
        </div>
      )}

      <Modal isOpen={!!selectedImage} backdrop="blur">
        {!!selectedImage && (
          <Carousel items={selectedImage} onClose={() => setSelectedImage(null)} />
        )}
      </Modal>

      <Modal
        size="2xl"
        isOpen={!!selectedOrder}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          {(onClose) => (
            <ModalView
              order={selectedOrder}
              onOpenImage={setSelectedImage}
              onClose={onClose}
              reloadList={() => reload(true)}
            />
          )}
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <Table aria-label="Trabajos realizados"
        topContent={
          <div className="flex flex-row justify-between items-center gap-4">
            <h3 className="text-xl font-medium text-primaryDark">Trabajos realizados</h3>
            <Button
              color="primary"
              startContent={<img src={IconExport} className="w-6 h-6 invert" alt="" />}
              onClick={() => download()}
            >
              Exportar reporte
            </Button>
          </div>
        }
        topContentPlacement="inside"
      >
        <TableHeader>
          <TableColumn>Trabajador</TableColumn>
          <TableColumn>Asignada</TableColumn>
          <TableColumn>Número</TableColumn>
          <TableColumn>Fecha</TableColumn>
          <TableColumn>Horas trabajadas</TableColumn>
          <TableColumn align="end" />
        </TableHeader>
        <TableBody items={orders}>
          {(order) => {
            const { order: main, cleaner } = order.order_cleaner;
            const { store } = main;

            return (
              <TableRow key={order.id}>
                <TableCell>
                  { cleaner?.person?.fullName }
                </TableCell>
                <TableCell>
                  { store?.name }
                </TableCell>
                <TableCell className="text-primary cursor-pointer">
                  <span onClick={() => setSelectedOrder(order)}>
                    { main?.id }
                  </span>
                </TableCell>
                <TableCell>
                  { moment(order.date).format('DD/MM/YYYY') }
                </TableCell>
                <TableCell>
                  { getTotalTime(order.total_time) }
                </TableCell>
                <TableCell align="right">
                  <div className="relative flex justify-end items-center gap-2">
                    <Tooltip content="Ver detalles">
                      <IconEye onClick={() => setSelectedOrder(order)} />
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            )
          }}
        </TableBody>
      </Table>

      <div className="flex w-full justify-center mt-4">
        <Pagination
          showControls
          variant="bordered"
          page={pagination.page}
          total={pagination.pages}
          onChange={goToPage}
        />
      </div>
    </>
  );
}

const Carousel = ({ items, onClose }) => {
  const [selected, setSelected] = useState(items.selected);

  return (
    <div
      className="w-screen h-screen fixed inset-0 z-[100] flex justify-center items-center bg-white/30"
      onClick={onClose}
    >
      <IconX size={28} className="absolute top-4 right-8" onClick={onClose} />

      <Image
        classNames={{ img: 'object-contain max-w-3xl max-h-[70vh] mr-4' }}
        src={items?.images[selected]}
        alt=""
      />

      {items?.images?.length > 1 && (
        <>
          {selected !== 0 && (
            <div className="absolute left-4 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
              <IconArrowDown
                size={34}
                className="rotate-90"
                onClick={e => {
                  e.stopPropagation();
                  setSelected(selected - 1);
                }}
              />
            </div>
          )}

          {(selected !== (items?.images?.length - 1)) && (
            <div className="absolute right-8 p-2 rounded-full bg-white/80 hover:bg-white transition-colors">
              <IconArrowDown
                size={34}
                className="-rotate-90"
                onClick={e => {
                  e.stopPropagation();
                  setSelected(selected + 1);
                }}
              />
            </div>
          )}

          <div className="absolute bottom-8 flex flex-wrap gap-4">
            {items?.images?.map((image, index) => (
              <Avatar
                key={index}
                radius="sm"
                size="lg"
                className="cursor-pointer"
                isBordered={selected === index}
                color="primary"
                src={image}
                onClick={e => {
                  e.stopPropagation();
                  setSelected(index);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {

  const initialFilter = {
    order: '',
    since: '',
    until: '',
    status: '',
    search_store: '',
    search_cleaner: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[11rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Número de orden"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        onClear={() => onChange('', 'order')}
        variant="bordered"
        value={form.order}
        onValueChange={v => onChange(v, 'order')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[11rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Nombre del trabajador"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        onClear={() => onChange('', 'search_cleaner')}
        variant="bordered"
        value={form.search_cleaner}
        onValueChange={v => onChange(v, 'search_cleaner')}
      />
      <Input
        classNames={{
          base: 'w-full sm:max-w-[11rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Nombre de la tienda"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        onClear={() => onChange('', 'search_store')}
        variant="bordered"
        value={form.search_store}
        onValueChange={v => onChange(v, 'search_store')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
      />
      <Select
        label="Estatus"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[9rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={form.status ? [form.status]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'status')}
      >
        {statuses.map((status) => (
          <SelectItem key={status.value} value={status.value}>
            { status.label }
          </SelectItem>
        ))}
      </Select>
      <Select
        label="Turno"
        labelPlacement="outside"
        placeholder="Seleccionar"
        variant="bordered"
        className="max-w-xs"
        classNames={{ base: 'w-full sm:max-w-[9rem]', trigger: 'border-1 bg-white' }}
        disallowEmptySelection
        selectedKeys={form.turn ? [form.turn]:[]}
        onSelectionChange={v => onChange(v.currentKey, 'turn')}
      >
        {turns.map((i) => (
          <SelectItem key={i.value} value={i.value}>
            { i.label }
          </SelectItem>
        ))}
      </Select>

      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const ModalView = ({ order, onOpenImage, onClose, reloadList }) => {

  const [photos, setPhotos] = useState(order?.photos?.map(x => ({ ...x, url: fromPhotos(x.url) })) || []);

  const main = order?.order_cleaner?.order;
  const store = main?.store;
  const mainTimeStartsAt = !!main?.time_starts_at
    ? moment(main.time_starts_at, 'HH:mm:ss').format('hh:mm a')
    : 'Fecha no definida';

  const mainTimeEndsAt = !!main?.time_ends_at
    ? moment(main.time_ends_at, 'HH:mm:ss').format('hh:mm a')
    : 'Fecha no definida';

  const timeStartsAt = !!order?.started_at
    ? moment(order.started_at).format('DD MMM YYYY / hh:mm a')
    : 'Fecha no definida';

  const timeEndsAt = !!order?.ended_at
    ? moment(order.ended_at).format('DD MMM YYYY / hh:mm a')
    : 'Fecha no definida';

  const setMap = ({ mapName, lat, lng }) => {
    const map = new google.maps.Map(document.getElementById(mapName), {
      zoom: 13,
      center: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });

    new google.maps.Marker({
      map,
      position: map.getCenter(),
      title: '',
      animation: google.maps.Animation.DROP,
      draggable: false,
    });
  }

  const openImage = (index) => {
    onOpenImage({
      selected: index,
      images: order?.photos?.map(x => fromPhotos(x.url))
    });
  }

  const uploadPhoto = async (photo) => {
    try {
      await OrdersService.uploadPhoto({
        photo,
        order_execution_id: order.id,
        hasFile: true,
      });

      setPhotos([...photos, {
        id: new Date().getTime(),
        url: URL.createObjectURL(photo),
      }]);

      reloadList();

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (order.init_lat && order.init_lng)
      setMap({ mapName: 'start-map', lat: order.init_lat, lng: order.init_lng });

    if (order.end_lat && order.end_lng)
      setMap({ mapName: 'end-map', lat: order.end_lat, lng: order.end_lng });
  }, [order]);

  return (
    <div className="items-start">
      <ModalBody className="items-start">
        <div className="grid grid-cols-2 gap-4 w-full">
          <div>
            <div className="w-full">
              <p className="font-semibold text-lg">{ store?.name }</p>
              <div className="text-success">N° { String(store.id).padStart(4,'0') }</div>
              <div className="text-sm font-normal">N° Orden: { String(main.id).padStart(4,'0') }</div>

              <div className="mt-4 flex text-sm items-center gap-2 text-primary">
                <IconMapPin size={20} />
                <p>Dirección</p>
              </div>
              <p className="text-sm">{ store?.address }</p>

              <p className="font-medium text-red mt-2">Fecha y hora programada</p>
              <div className="flex flex-col gap-2 text-sm">
                <div className="flex items-center gap-2">
                  <IconCalendarMonth stroke={1.5} size={20} color="gray" />
                  <p>{ moment(order.date).format('DD MMMM YYYY') }</p>
                </div>
                <div className="flex items-center gap-2">
                  <IconClock stroke={1.5} size={20} color="gray" />
                  <span>{ mainTimeStartsAt } - { mainTimeEndsAt }</span>
                </div>
              </div>

              <div className="w-full flex flex-col items-center my-4">
                <p className="text-sm">Horas trabajadas</p>
                <Chip size="lg" color="success" className="text-white">
                  { getTotalTime(order?.total_time) }
                </Chip>
              </div>

              {!!main.priority && (
                <p className="text-sm font-medium text-blue-800 mt-4">
                  Observaciones: { main?.priority }
                </p>
              )}
              <p className="text-sm font-medium text-blue-800 my-2">
                Trabajador asignado: { order?.order_cleaner?.cleaner?.person?.fullName }
              </p>
              <Divider />
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="w-full text-sm">
              <p className="font-bold text-base">Detalles de ubicación del servicio</p>
              <p className="font-medium text-blue-800 mt-2">Inició</p>
              <p>{ timeStartsAt }</p>
              {(!order.init_lat || !order.init_lng) ? (
                <p>No tiene una ubicación definida en el mapa</p>
              ) : (
                <div id="start-map" className="h-40" />
              )}
            </div>

            <div className="w-full text-sm">
              <p className="font-medium text-blue-800">Finalizó</p>
              <p>{ timeEndsAt }</p>
              {(!order.end_lat || !order.end_lng) ? (
                <p>No tiene una ubicación definida en el mapa</p>
              ) : (
                <div id="end-map" className="h-40" />
              )}
            </div>
          </div>
        </div>

        <div className="w-full">
          <p className="font-bold text-base">Material fotográfico del servicio</p>

          <div className="flex flex-wrap gap-3 mt-4">
            {photos?.map((x, index) => (
              <Avatar
                key={x.id}
                radius="sm"
                size="lg"
                className="cursor-pointer"
                src={x.url}
                onClick={() => openImage(index)}
              />
            ))}
          </div>

          <Button
            as="label"
            htmlFor="file-upload"
            className="bg-secondary text-white font-semibold mt-4"
            onPress={() => {}}
          >
            Añadir fotos
          </Button>

          <input
            id="file-upload"
            type="file"
            hidden
            onChange={e => {
              uploadPhoto(e.target.files[0]);
              e.target.value = '';
            }}
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="light" onPress={onClose}>Cerrar</Button>
      </ModalFooter>
    </div>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    order: '',
    since: '',
    until: '',
    status: '',
    search_store: '',
    search_cleaner: '',
    turn: '',
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await OrdersService.findHistory(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const download = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await OrdersService.download(filters);
      if (!response) throw new Error('No hay datos para exportar');
      const res = await fetch(fromPhotos(response));
      const blob = await res.blob();
      const extension = String(response).split('.').pop();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `Reporte - Trabajos realizados - ${Date.now()}.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    canResetFilter: canResetFilter(),
    changePerPage,
    download,
    filterBy,
    filters,
    goToPage,
    isLoading: !canFetch,
    orders: data,
    pagination,
    reload,
  }
}

export default History;