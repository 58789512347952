/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Button, Divider, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User, Pagination } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { classNames, Constants, formatAmount, fromPhotos } from '../../utils';
import { PayrollService } from '../../services';
import { CurrencyInput } from '../../components';

const MODAL_ACTION = {
  NONE: 0,
  EARNING: 1,
  PAYMENT: 2,
  SEND_RECEIPT: 3,
};

const GENERATE = {
  SEND: 1,
  DOWNLOAD: 2,
}

const Calculation = () => {

  const [selectedItem, setSelectedItem] = useState();
  const [modalAction, setModalAction] = useState(MODAL_ACTION.NONE);
  const { banks, canResetFilter, filters, data, dataSupervisor, earnings, filterBy, addEarnings, reload, goToPage, pagination, isLoading, generateReceipt } = useFetchTable();

  const onSelectItem = (order, action) => {
    setSelectedItem(order);
    setModalAction(action);
  }

  const closeModal = () => {
    setSelectedItem(null);
    setModalAction(MODAL_ACTION.NONE);
  }

  const onGeneratePayment = async (item) => {
    if (item.orders?.some(x => !Number(x?.value_hour)))
      return toast.error('Debe ingresar los valores en el módulo de tienda antes de registrar el pago');
    onSelectItem(item, MODAL_ACTION.PAYMENT);
  }

  const isCleaner = filters.level_id === Constants.LEVELS.CLEANER;
  const isSupervisor = filters.level_id === Constants.LEVELS.SUPERVISOR;

  return (
    <>
      <Modal
        size="md"
        isOpen={modalAction === MODAL_ACTION.EARNING}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalEarning
            item={selectedItem}
            earnings={earnings}
            onSuccess={form => {
              addEarnings(form);
              closeModal();
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="xl"
        isOpen={modalAction === MODAL_ACTION.PAYMENT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalPayment
            item={selectedItem}
            banks={banks}
            earnings={earnings}
            onSuccess={data => {
              onSelectItem({ ...selectedItem, payment_date: data.date, id: data.id }, MODAL_ACTION.SEND_RECEIPT);
              reload();
            }}
          />
        </ModalContent>
      </Modal>

      <Modal
        size="lg"
        isOpen={modalAction === MODAL_ACTION.SEND_RECEIPT}
        onClose={() => closeModal()}
        backdrop="blur"
        scrollBehavior="outside"
      >
        <ModalContent>
          <ModalSendReceipt
            item={selectedItem}
            earnings={earnings}
            onDownload={() => generateReceipt(selectedItem, GENERATE.DOWNLOAD)}
            onSuccess={() => generateReceipt(selectedItem, GENERATE.SEND)}
          />
        </ModalContent>
      </Modal>

      <Filters
        canResetFilter={canResetFilter}
        filterBy={filterBy}
        resetFilter={() => reload()}
      />

      <div className="flex gap-1 text-white font-semibold">
        <div
          className={classNames('ml-4 px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.CLEANER ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.CLEANER, 'level_id')}
        >
          <span>Limpiadores</span>
        </div>

        <div
          className={classNames('px-4 py-1 cursor-pointer', filters.level_id === Constants.LEVELS.SUPERVISOR ? 'bg-[#75D9EE]' : 'bg-blue')}
          onClick={() => filterBy(Constants.LEVELS.SUPERVISOR, 'level_id')}
        >
          <span>Supervisores</span>
        </div>
      </div>

      {isCleaner && data.map((item, itemIdx) => {
        const cleaner = item?.user //Datos del limpiador
        const periodInit = item.period_init;
        const itemsLength = item?.orders?.length;
        const othersEarnings = earnings
          .filter(x => x.cleaner_id === item.user.id && x.period_init === periodInit)
          .map(x => +x.amount)
          .reduce((acc, x) => acc + x, 0);

        const total = (othersEarnings + (Number(item?.totalEarnings) || 0));

        return (
          <Fragment key={itemIdx}>
            <Table
              aria-label="Desempeño Semanal"
              classNames={{
                base: 'mb-4',
                wrapper: 'p-2',
                th: 'whitespace-pre-line',
                td: 'border-t-1 first:border-r-1 border-collapse',
                tr: 'border-b-1',
              }}
            >
              <TableHeader>
                <TableColumn className="w-48">Trabajador</TableColumn>
                <TableColumn>Tiendas{'\n'}asignadas</TableColumn>
                <TableColumn>Número de{'\n'}tienda</TableColumn>
                <TableColumn>Tipo{'\n'}tienda</TableColumn>
                <TableColumn>Valor día $</TableColumn>
                <TableColumn>Hrs./min{'\n'}Trabajadas</TableColumn>
                <TableColumn>Valor Hrs./min{'\n'}Trabajadas</TableColumn>
                <TableColumn>Hrs./min{'\n'}Extras</TableColumn>
                <TableColumn>Valor Hrs./min{'\n'}Extras</TableColumn>
                <TableColumn>Salario{'\n'}Subtotal</TableColumn>
                <TableColumn>Otros{'\n'}ingresos $</TableColumn>
                <TableColumn>Salario Neto{'\n'}Total $</TableColumn>
                <TableColumn>
                  Período{'\n'}{ moment(item.period_init).format('DD-MM-YYYY') } al{'\n'}{ moment(item.period_finish).format('DD-MM-YYYY') }
                </TableColumn>
              </TableHeader>
              <TableBody>
                {item?.orders?.map((order, orderIdx) => {
                  const isPerHour = order?.store?.payment_type === Constants.STORE.PAYMENT_TYPE.PER_HOUR;

                  if (orderIdx === 0) return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell className="w-48 bg-[#DEF6FF]" rowSpan={itemsLength}>
                        <div className="w-48 overflow-hidden">
                          <User
                            classNames={{ name: 'truncate', description: 'truncate' }}
                            avatarProps={{ showFallback: true, radius: 'full', src: cleaner?.person?.photo ? fromPhotos(cleaner.person.photo) : null }}
                            name={cleaner?.person?.fullName}
                            description={cleaner?.email}
                          />
                        </div>
                      </TableCell>
                      <TableCell>{ order?.store?.name }</TableCell>
                      <TableCell>{ order?.store?.number }</TableCell>
                      <TableCell>{ isPerHour ? 'Horas' : 'Jornada' }</TableCell>
                      <TableCell>{ isPerHour ? '' : order?.store?.value_day }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsTrabajadas : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsExtras : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>{ formatAmount(order?.subtotal) }</TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(othersEarnings) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength}>
                        <div className="flex items-center">
                          <p>{  formatAmount(total) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} align="right" className="w-36">
                        <div className="flex flex-col gap-2 w-36">
                          <Button
                            className="text-primary border-primary font-semibold"
                            variant="bordered"
                            size="sm"
                            onClick={() => onSelectItem(item, MODAL_ACTION.EARNING)}
                          >
                            Registrar otro ingreso
                          </Button>

                          <Button
                            className="bg-[#5DAD4A] text-white font-semibold"
                            size="sm"
                            onClick={() => onGeneratePayment(item)}
                          >
                            Registrar pago
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );

                  return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell hidden />
                      <TableCell>{ order?.store?.name }</TableCell>
                      <TableCell>{ order?.store?.number }</TableCell>
                      <TableCell>{ isPerHour ? 'Horas' : 'Jornada' }</TableCell>
                      <TableCell>{ isPerHour ? '' : order?.store?.value_day }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsTrabajadas : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>{ isPerHour ? order?.hsExtras : '' }</TableCell>
                      <TableCell>{ isPerHour ? formatAmount(order?.value_hour) : '' }</TableCell>
                      <TableCell>{ formatAmount(order?.subtotal) }</TableCell>
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell hidden />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {(!isLoading && data.length === 0) && (
              <div className="w-full py-4">
                <p className="text-center text-gray-500">No se encontraron resultados</p>
              </div>
            )}
          </Fragment>
        )
      })}

      {isSupervisor && dataSupervisor.map((item, itemIdx) => {
        const supervisor = item?.user //Datos del supervisor
        const periodInit = item?.period_init;
        const itemsLength = item?.stores?.length;
        const othersEarnings = earnings
          .filter(x => x.cleaner_id === item.user.id && x.period_init === periodInit)
          .map(x => +x.amount)
          .reduce((acc, x) => acc + x, 0);

        const total = (othersEarnings + (Number(item?.totalEarnings) || 0));

        return (
          <Fragment key={itemIdx}>
            <Table
              aria-label="Desempeño Semanal"
              classNames={{
                base: 'mb-4',
                wrapper: 'p-2',
                th: 'whitespace-pre-line',
                td: 'border-t-1 first:border-r-1 border-collapse',
                tr: 'border-b-1',
              }}
              topContent={
                <div className="flex items-center justify-between">
                  <div className="max-w-min bg-[#DEF6FF] px-3 py-2">
                    <User
                      classNames={{ name: 'truncate', description: 'truncate' }}
                      avatarProps={{ showFallback: true, radius: 'full', src: supervisor?.person?.photo ? fromPhotos(supervisor.person.photo) : null }}
                      name={supervisor?.person?.fullName}
                      description={supervisor?.email}
                    />
                  </div>

                  <div className="font-semibold">
                    <span>Tiendas asignadas </span>
                    <span className="text-xl">{ item?.stores?.length }</span>
                  </div>
                </div>
              }
            >
              <TableHeader>
                <TableColumn className="w-48">Tiendas{'\n'}asignadas</TableColumn>
                <TableColumn>Número de{'\n'}Tienda</TableColumn>
                <TableColumn>Valor{'\n'}Tienda</TableColumn>
                <TableColumn>Comisión{'\n'}Supervisor</TableColumn>
                <TableColumn>Visitas {'\n'}Realizadas</TableColumn>
                <TableColumn>Ingresos{'\n'}Subtotal</TableColumn>
                <TableColumn>Otros{'\n'}Ingresos</TableColumn>
                <TableColumn>Total{'\n'}Ingresos</TableColumn>
                <TableColumn>
                  Período{'\n'}{ moment(item.period_init).format('DD-MM-YYYY') } al{'\n'}{ moment(item.period_finish).format('DD-MM-YYYY') }
                </TableColumn>
              </TableHeader>
              <TableBody>
                {item?.stores?.map((store, orderIdx) => {
                  if (orderIdx === 0) return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell className="w-48">{ store?.name }</TableCell>
                      <TableCell>{ store?.number }</TableCell>
                      <TableCell>{ formatAmount(store?.value_store, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_supervisor, '$') }</TableCell>
                      <TableCell>{ store?.visits_count }</TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(item?.totalEarnings) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} className="border-l-1">
                        <div className="flex items-center">
                          <p>{ formatAmount(othersEarnings) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength}>
                        <div className="flex items-center">
                          <p>{  formatAmount(total) }</p>
                        </div>
                      </TableCell>
                      <TableCell rowSpan={itemsLength} align="right" className="w-36">
                        <div className="flex flex-col gap-2 w-36">
                          <Button
                            className="text-primary border-primary font-semibold"
                            variant="bordered"
                            size="sm"
                            onClick={() => onSelectItem(item, MODAL_ACTION.EARNING)}
                          >
                            Registrar otro ingreso
                          </Button>

                          <Button
                            className="bg-[#5DAD4A] text-white font-semibold"
                            size="sm"
                            onClick={() => onGeneratePayment(item)}
                          >
                            Registrar pago
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );

                  return (
                    <TableRow key={`${itemIdx}-${orderIdx}`}>
                      <TableCell>{ store?.name }</TableCell>
                      <TableCell>{ store?.number }</TableCell>
                      <TableCell>{ formatAmount(store?.value_store, '$') }</TableCell>
                      <TableCell>{ formatAmount(store?.value_supervisor, '$') }</TableCell>
                      <TableCell>{ store?.visits_count }</TableCell>
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell hidden />
                      <TableCell hidden />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            {(!isLoading && dataSupervisor.length === 0) && (
              <div className="w-full py-4">
                <p className="text-center text-gray-500">No se encontraron resultados</p>
              </div>
            )}
          </Fragment>
        )
      })}

      {(!isLoading && ((isCleaner && data.length > 0) || (isSupervisor && dataSupervisor.length > 0))) && (
        <div className="flex w-full justify-center mt-4">
          <Pagination
            showControls
            variant="bordered"
            page={pagination.page}
            total={pagination.pages}
            onChange={goToPage}
          />
        </div>
      )}
    </>
  );
}

const ModalEarning = ({ item, onSuccess }) => {
  const [form, setForm] = useState({
    cleaner_id: item.user.id,
    period_init: item.period_init,
    amount: '',
    description: '',
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Otro Ingreso</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="flex flex-col gap-4">
            <CurrencyInput
              classNames={{ inputWrapper: 'border-1 !h-10 bg-white' }}
              label="Monto a asignar $"
              placeholder=" "
              value={form.amount}
              onValueChange={v => onChange(v, 'amount')}
            />
            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Motivo"
              labelPlacement="outside"
              placeholder="Escribir"
              variant="bordered"
              value={form.description}
              onValueChange={v => onChange(v, 'description')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={() => onSuccess(form)}>Guardar</Button>
      </ModalFooter>
    </div>
  )
}

const ModalSendReceipt = ({ item, earnings, onDownload, onSuccess }) => {
  const periodInit = item.period_init;
  const items = item.user.level_id === Constants.LEVELS.SUPERVISOR
    ? item?.stores?.map(x => ({ name: x?.name, subtotal: x.subtotal }))
    : item?.orders?.map(x => ({ name: x?.store?.name, subtotal: x.subtotal }));

  const othersEarnings = earnings
    .filter(x => x.cleaner_id === item.user.id && x.period_init === periodInit)
    .map(x => +x.amount)
    .reduce((acc, x) => acc + x, 0);

  const total = (othersEarnings + item.totalEarnings);

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col"></ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full px-4">
          <User
            classNames={{
              name: 'truncate text-md font-semibold',
              description: 'truncate text-sm text-gray',
            }}
            avatarProps={{ src: process.env.REACT_APP_BASE + 'img/logo.png', radius: 'none', className: 'bg-white' }}
            // name="Charlotte, NC"
            // description="RFC; 998554699"
          />

          <div className="my-4 flex flex-1 justify-between text-sm">
            <div>
              <p className="font-semibold">Trabajador: { item?.user?.person?.fullName }</p>
              <p>DNI: { item?.user?.person?.document }</p>
            </div>
            <div className="text-right">
              <p className="font-semibold">Fecha emisión</p>
              <p>{ moment(item?.payment_date).format('DD-MM-YYYY') }</p>
            </div>
          </div>

          <div className="grid [&>p:nth-child(even)]:text-right text-sm" style={{ gridTemplateColumns: '5fr 2fr' }}>
            <p className="font-semibold">Concepto</p>
            <p className="font-semibold">Monto $</p>
            {items?.map((order, orderIdx) => (
              <Fragment key={orderIdx}>
                <p>Devengado { order?.name }</p>
                <p>{ formatAmount(order?.subtotal) }</p>
              </Fragment>
            ))}
          </div>
        </div>

        <Divider className="my-2" />

        <div className="w-full px-4">
          <div
            className="grid [&>p:nth-child(even)]:text-right text-sm font-semibold gap-4 mb-4"
            style={{ gridTemplateColumns: '5fr 2fr' }}
          >
            <p>Salario Bruto Devengado $</p>
            <p>{ formatAmount(item.totalEarnings) }</p>
            <p>Otros ingresos</p>
            <p>{ formatAmount(othersEarnings) }</p>
          </div>

          <div
            className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex flex-1 justify-between items-center [&>p:nth-child(even)]:text-right text-sm font-bold text-primary gap-4 -mx-4"
          >
            <p>Total Salario Neto $</p>
            <p className="text-medium">{ formatAmount(total) }</p>
          </div>

          <p className="my-4 text-sm text-center whitespace-pre-line">
            De no emitir reclamo, certifico haber recibido la cantidad{'\n'}indicada de salario neto, así como copia del presente recibo. 
          </p>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button variant="flat" color="primary" onPress={onDownload}>Descargar</Button>
        <Button color="primary" onPress={onSuccess}>Enviar recibo al usuario</Button>
      </ModalFooter>
    </div>
  )
}

const ModalPayment = ({ item, earnings, banks, onSuccess }) => {
  const isSupervisor = item?.user?.level_id === Constants.LEVELS.SUPERVISOR;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    cleaner_id: item.user.id,
    amount: 0,
    bank_from: '',
    bank_to: '',
    date: '',
    reference: '',
    period_init: isSupervisor ? item.period_init : null,
    period_finish: isSupervisor ? item.period_finish : null,
  });

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
  }

  const isValidForm = () => {
    const onError = (msg) => {
      toast.error(msg);
      return false;
    }

    if (!form.cleaner_id)
      return onError('Debe seleccionar un limpiador');

    if (!form.amount)
      return onError('El monto no puede ser cero');

    if (!form.bank_to)
      return onError('Debe seleccionar el banco de destino');

    if (!form.bank_from)
      return onError('Debe seleccionar el banco de emisor');

    if (!form.date)
      return onError('Debe seleccionar una fecha');

    return true;
  }

  const onSubmit = async () => {
    if (!isValidForm()) return;
    setIsLoading(true);

    try {
      const data = {
        ...form,
        bank_from: +form.bank_from,
        bank_to: +form.bank_to,
        amount: +form.amount,
      };

      const res = await PayrollService.createPayment(data);
      toast.success('Pago registrado con éxito');
      data.id = res.id;
      onSuccess(data);

    } catch (error) {
      console.log(error)
      toast.error(error?.message);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    const periodInit = item.period_init;
    const othersEarnings = earnings.filter(x => x.cleaner_id === item.user.id && x.period_init === periodInit);
    const othersEarningsAmount = othersEarnings.map(x => +x.amount).reduce((acc, x) => acc + x, 0);
    const total = othersEarningsAmount + Number(item?.totalEarnings);

    let details = [];

    if (isSupervisor) {
      details = item?.stores?.map(i => ({
        payroll_id: i?.payroll_id,
        amount: i?.subtotal || 0,
      }));

    } else {
      details = item?.orders?.map(i => ({
        payroll_id: i?.payroll_id,
        amount: i?.subtotal || 0,
      }));
    }

    othersEarnings.forEach(x => details.push({ amount: x.amount, description: x.description }));
    setForm(s => ({ ...s, amount: total, details }));
  }, []);

  return (
    <div className="items-start">
      <ModalHeader className="flex flex-col">
        <div className="text-center text-[#202C4E]">Registrar Pago</div>
      </ModalHeader>
      <ModalBody className="items-start">
        <div className="w-full space-y-6">
          <div className="border-2 border-primary bg-[#D3E8ED] rounded-lg p-4 flex justify-between gap-4 flex-1">
            <div className="overflow-hidden">
              <User
                classNames={{
                  name: 'truncate text-md font-semibold',
                  description: 'truncate text-sm text-gray',
                }}
                avatarProps={{ showFallback: true, radius: 'full', src: item?.user?.person?.photo ? fromPhotos(item.user.person.photo) : null }}
                name={item?.user?.person?.fullName}
                description={item?.user?.email}
              />
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-[#202C4E]">{ formatAmount(form.amount) }</p>
              <p className="text-[13px]">Monto Pagado $</p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <Select
              label="Banco destino"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.bank_to ? [form.bank_to]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'bank_to')}
            >
              {banks.map((el) => (
                <SelectItem key={el.id} value={el.id}>{ el.name }</SelectItem>
              ))}
            </Select>

            <Select
              label="Banco emisor"
              labelPlacement="outside"
              placeholder="Seleccionar"
              variant="bordered"
              className="max-w-xs"
              classNames={{ base: 'w-full', trigger: 'border-1 bg-white' }}
              disallowEmptySelection
              selectedKeys={form.bank_from ? [form.bank_from]:[]}
              onSelectionChange={v => onChange(v.currentKey, 'bank_from')}
            >
              {banks.map((el) => (
                <SelectItem key={el.id} value={el.id}>{ el.name }</SelectItem>
              ))}
            </Select>

            <Input
              type="date"
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
                input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
              }}
              label="Fecha de pago"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.date}
              onValueChange={v => onChange(v, 'date')}
            />

            <Input
              classNames={{
                base: 'w-full',
                inputWrapper: 'border-1 h-10 bg-white',
              }}
              label="Referencia"
              labelPlacement="outside"
              placeholder=" "
              variant="bordered"
              value={form.reference}
              onValueChange={v => onChange(v, 'reference')}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-evenly">
        <Button color="primary" onPress={onSubmit} isLoading={isLoading}>
          Guardar
        </Button>
      </ModalFooter>
    </div>
  )
}

const Filters = ({ canResetFilter, filterBy, resetFilter }) => {
  const initialFilter = {
    search: '',
    since: '',
    until: '',
  };
  const [form, setForm] = useState(initialFilter);

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  return (
    <section className="mb-4 flex flex-col lg:flex-row flex-wrap items-end gap-4">
      <Input
        classNames={{
          base: 'w-full sm:max-w-[17rem]',
          inputWrapper: 'border-1 h-10 bg-white',
        }}
        label="Buscar"
        labelPlacement="outside"
        startContent={<IconSearch />}
        isClearable
        placeholder="Nombre del trabajador, tienda"
        onClear={() => onChange('', 'search')}
        variant="bordered"
        value={form.search}
        onValueChange={v => onChange(v, 'search')}
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full sm:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.since ? '[]':'foreground-400'}`,
        }}
        label="Desde"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.since}
        onValueChange={v => onChange(v, 'since')}
        min="2025-01-01"
      />
      <Input
        type="date"
        classNames={{
          base: 'w-full lg:max-w-[9rem]',
          inputWrapper: 'border-1 h-10 bg-white',
          input: `pr-0 text-${!!form.until ? '[]':'foreground-400'}`,
        }}
        label="Hasta"
        labelPlacement="outside"
        placeholder=" "
        variant="bordered"
        value={form.until}
        onValueChange={v => onChange(v, 'until')}
        min="2025-01-01"
      />
      {canResetFilter && (
        <Button
          variant="light"
          className="text-primaryDark"
          onClick={() => {
            setForm(initialFilter);
            resetFilter();
          }}
        >
          Limpiar filtros
        </Button>
      )}
    </section>
  )
}

const useFetchTable = () => {
  const initialFilters = {
    page: 1,
    perPage: 5,
    search: '',
    since: '',
    until: '',
    level_id: Constants.LEVELS.CLEANER,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: 5,
  };

  const [data, setData] = useState([]);
  const [dataSupervisor, setDataSupervisor] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);
  const [earnings, setEarnings] = useState([]);
  const [banks, setBanks] = useState([]);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.getPayroll(filters);
      const { data, ...rest } = response;
      const parsedData = [];

      data.forEach(item => {
        const { cleaner: user, first_fortnight, second_fortnight } = item;

        if (first_fortnight && !Array.isArray(first_fortnight)) {
          parsedData.push({ user, ...first_fortnight });
        }

        if (second_fortnight && !Array.isArray(second_fortnight)) {
          parsedData.push({ user, ...second_fortnight });
        }
      });

      setData(parsedData.map((x, i) => ({ ...x, index: i })));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const fetchDataSupervisor = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await PayrollService.getPayrollSupervisor(filters);
      const { data, ...rest } = response;
      const parsedData = [];

      data.forEach(item => {
        const { supervisor: user, ...rest } = item;

        Object.values(rest).forEach(x => {
          if (x.first_fortnight && !Array.isArray(x.first_fortnight)) {
            parsedData.push({ user, ...x.first_fortnight });
          }
  
          if (x.second_fortnight && !Array.isArray(x.second_fortnight)) {
            parsedData.push({ user, ...x.second_fortnight });
          }
        });
      });

      setDataSupervisor(parsedData.map((x, i) => ({ ...x, index: i })));
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const generateReceipt = async (item, action) => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const form = {
        payment_id: item.id,
        send_to_mail: action === GENERATE.SEND,
      };

      const response = await PayrollService.generateReceipt(form);

      if (action === GENERATE.DOWNLOAD) {
        const res = await fetch(fromPhotos(response.url));
        const blob = await res.blob();
        const extension = String(response.url).split('.').pop();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `Recibo - ${moment.now()}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

      } else {
        toast.success('Recibo enviado con éxito');
      }

    } catch (error) {
      onError(String(error));
    }

    setCanFetch(true);
  }

  const getBanks = async () => {
    try {
      const banks = await PayrollService.getBanks();
      setBanks(banks);

    } catch (error) {
      onError(String(error));
    }
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    setData([]);

    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  const addEarnings = (earning) => {
    setEarnings(s => {
      // Verificar si existe un ingreso adicional para el mismo trabajador
      const existingEarningIdx = s.findIndex(x => x.cleaner_id === earning.cleaner_id && x.order_id === earning.order_id);
      if (existingEarningIdx !== -1) {
        // Si existe, se sustituye por el nuevo ingreso
        s[existingEarningIdx] = earning;
        return s;
      }

      // Si no existe, se agrega al final
      return [ ...earnings, earning ];
    });
  }

  useEffect(() => {
    if (filters.level_id === Constants.LEVELS.SUPERVISOR) {
      fetchDataSupervisor();
    } else {
      fetchData();
    }

    getBanks();
  }, [filters]);

  return {
    addEarnings,
    banks,
    canResetFilter: canResetFilter(),
    changePerPage,
    data,
    dataSupervisor,
    earnings,
    filterBy,
    filters,
    generateReceipt,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
  }
}

export default Calculation;