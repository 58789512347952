export const SocketEvents = {
    LOCATION_SET: 'location/set',
    ROUTE: {
        NEW_ROUTE: 'route/new',
        ROUTE_ACCEPTED: 'route/accepted',
        DRIVER_ON_LOCATION: 'route/on-location',
        TRAVEL_INITED: 'route/inited',
        TRAVEL_CANCELLED: 'route/cancelled',
        DRIVER_ARRIVED: 'route/driver-arrived',
        TRAVEL_FINISH: 'route/finish',
    },
    USER: {
        DISABLE_USER: 'user/disable',
        SET_SERVICE: 'user/set-service',
        CHECK_DISABLED: 'user/check-disabled',
    }
};
