/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Pagination, Input, ModalBody, ModalFooter, Button, Spinner } from '@nextui-org/react';
import toast from 'react-hot-toast';
import { classNames, Constants } from '../../utils';
import { CleanersService, StoresService } from '../../services';

const TAB = {
  ASSIGNED: 1,
  UNASSIGNED: 2,
};

const ModalAssignStores = ({ user, onSuccess }) => {
  const [tab, setTab] = useState(TAB.UNASSIGNED);
  const { assignStores, filters, filterBy, goToPage, isLoading, pagination, selectedStores, stores, toggleStore } = useFetchTable(user);

  const onSubmit = async () => {
    const success = await assignStores();
    if (success) onSuccess();
  }

  const filteredStores = user.user_stores.filter(s => String(s.store?.name)?.toLowerCase()?.includes(filters.search?.toLowerCase()));

  return (
    <>
      <ModalBody>
        <h1 className="font-bold text-2xl text-primary text-center">
          Tiendas Supervisor
        </h1>

        <Input
          classNames={{
            inputWrapper: 'border-1 h-10',
            label: 'font-semibold',
          }}
          label="Nombre de la tienda"
          labelPlacement="outside"
          placeholder="Buscar"
          variant="bordered"
          radius="sm"
          onValueChange={v => filterBy(v, 'search')}
        />

        <div className="flex gap-1 text-white font-semibold">
          <div
            className={classNames('px-4 py-1 cursor-pointer', tab === TAB.UNASSIGNED ? 'bg-[#75D9EE]' : 'bg-blue')}
            onClick={() => setTab(TAB.UNASSIGNED)}
          >
            <span>Sin asignar</span>
          </div>

          <div
            className={classNames('px-4 py-1 cursor-pointer', tab === TAB.ASSIGNED ? 'bg-[#75D9EE]' : 'bg-blue')}
            onClick={() => setTab(TAB.ASSIGNED)}
          >
            <span>Asignadas</span>
          </div>
        </div>

        {isLoading && <Spinner />}

        {tab === TAB.UNASSIGNED && (
          <>
            <div className="flex flex-col gap-2">
              {stores.map((store, index) => {
                const isSelected = selectedStores.includes(store.id);
                return (
                  <div key={index} className="flex items-center gap-2 cursor-pointer" onClick={() => toggleStore(store.id)}>
                    <div className={classNames('w-4 h-4 rounded-sm', isSelected ? 'bg-green' : 'bg-gray-200')} />
                    <p>{ store.number } { store.name }</p>
                  </div>
                )
              })}
            </div>

            {!isLoading && (
              <div className="flex w-full justify-center mt-4">
                <Pagination
                  showControls
                  variant="bordered"
                  page={pagination.page}
                  total={pagination.pages}
                  onChange={goToPage}
                />
              </div>
            )}
          </>
        )}

        {tab === TAB.ASSIGNED && (
          <div className="flex flex-col gap-2">
            {filteredStores?.map((item, index) => (
              <div key={index} className="flex items-center gap-2 cursor-pointer">
                <p>{ item?.store?.number } { item?.store?.name }</p>
              </div>
            ))}

            {(!filteredStores.length && user?.user_stores.length) && (
              <p className="text-center my-8 text-gray-400">No hay coincidencias</p>
            )}

            {!user?.user_stores.length && (
              <p className="text-center my-8 text-gray-400">No tiene tiendas asignadas</p>
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-center">
        <Button color="primary" isDisabled={isLoading} onPress={onSubmit}>
          Guardar
        </Button>
      </ModalFooter>
    </>
  )
}

const useFetchTable = (user) => {
  const initialFilters = {
    page: 1,
    perPage: Constants.PER_PAGE,
    search: '',
    since: null,
    until: null,
    unassigned: true,
    user_id: user?.id,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [filters, setFilters] = useState(initialFilters);
  const [pagination, setPagination] = useState(initialPagination);
  const [selectedStores, setSelectedStores] = useState([]);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await StoresService.findAll(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const assignStores = async () => {
    if (!canFetch) return false;
    setCanFetch(false);

    try {
      const form = {
        user_id: user.id,
        stores: selectedStores.map(store_id => ({ store_id })),
      };

      await CleanersService.assignStores(form);
      toast.success('Tiendas establecidas con éxito');
      return true;

    } catch (error) {
      onError(String(error));
    }
    setCanFetch(true);
    return false;
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const onError = (msg) => toast.error(msg);

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    fetchData();
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setCanFetch(true);
      setFilters({ ...filters, page });
    }
  }

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  const toggleStore = (storeId) => {
    const index = selectedStores.indexOf(storeId);
    if (index === -1) {
      setSelectedStores([...selectedStores, storeId]);
    } else {
      setSelectedStores(selectedStores.filter(id => id !== storeId));
    }
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  useEffect(() => {
    setSelectedStores(user?.user_stores.map(item => item.store_id));
  }, []);

  return {
    assignStores,
    filters,
    filterBy,
    goToPage,
    isLoading: !canFetch,
    pagination,
    reload,
    selectedStores,
    stores: data,
    toggleStore,
  }
}

export default ModalAssignStores;