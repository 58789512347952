/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Input } from '@nextui-org/react';
import { IconSearch } from '@tabler/icons-react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { MonitorService } from '../../services';
import { classNames, Constants } from '../../utils';
const parser = new DOMParser();

const google = window.google;
const DELAY_STATUS = 99;
const pinSvgString = (id, color) => {
  return `<svg id="marker-exec-${id}" fill="${color ?? '#158DA5'}" width="56" height="56" viewBox="0 0 24 24" version="1.2" xmlns="http://www.w3.org/2000/svg"><path d="M17.657 7.304c-3.124-3.073-8.189-3.073-11.313 0-3.124 3.074-3.124 8.057 0 11.13l5.656 5.565 5.657-5.565c3.124-3.073 3.124-8.056 0-11.13zm-5.657 8.195c-.668 0-1.295-.26-1.768-.732-.975-.975-.975-2.561 0-3.536.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732c.975.975.975 2.562 0 3.536-.472.472-1.1.732-1.768.732z"/><mask id="mask"><circle cx="12" cy="13" r="5.8" fill="white" /></mask></svg>`;
}

const defaultLatLng = {
	latitude: 29.7604315123649,
	longitude: -95.36981707226198,
};

const getStatus = (status) => {
  switch (status) {
    case Constants.ORDER.STATUS.PENDING:
      return { label: '', color: 'blue', hex: '#b2e5f8' };

    case Constants.ORDER.STATUS.INITIATED:
      return { label: '', color: 'green', hex: '#80FF00' };

    case Constants.ORDER.STATUS.FINISHED:
      return { label: '', color: 'gray', hex: '#BBBBBB' };

    case Constants.ORDER.STATUS.CANCELLED:
      return { label: '', color: 'red', hex: '#f9b2b2' };

    case DELAY_STATUS:
      return { label: 'Demorado', color: 'orange', hex: '#FF9326' };

    default:
      return { label: '', color: 'gray', hex: '#BBBBBB' };
  }
}

const Monitor = () => {
  const initialFilter = {
    search: '',
    date: moment().format('YYYY-MM-DD'),
    isPaginated: false,
  };

  const mapRef = useRef();
  const [mapMarkers, setMapMarkers] = useState([]);
  const { filterBy, data } = useFetchTable();
  const [form, setForm] = useState(initialFilter);
  const today = moment().format('YYYY-MM-DD');

  const onChange = (value, target) => {
    setForm(s => ({ ...s, [target]: value }));
    filterBy(value, target);
  }

  const setMap = () => {
    mapRef.current = new google.maps.Map(document.getElementById('map'), {
      mapId: 'MAIN_MAP',
      zoom: 13,
      center: new google.maps.LatLng(parseFloat(defaultLatLng.latitude), parseFloat(defaultLatLng.longitude)),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });
  }

  const removeMapMarkers = () => {
    for (const marker of mapMarkers) {
      marker.setMap(null);
    }
    setMapMarkers([]);
  }

  const selectItem = (item) => {
    if (!item.init_lat || !item.init_lng)
      return toast.error('Para visualizar la ubicacion en el mapa el usuario debe iniciar la orden asignada');

    mapRef.current.setCenter({ lat: +item.init_lat, lng: +item.init_lng });
    const el = document.getElementById(`marker-exec-${item.id}`);
    if (el.parentElement.parentElement.click)
      el.parentElement.parentElement.click();
  }

  useEffect(() => {
    setMap();
  }, []);

  useEffect(() => {
    removeMapMarkers();
    if (!data.length) return;

    const markers = [];
    const bounds = new google.maps.LatLngBounds();

    data.forEach((item, index) => {
      if (!item.init_lat || !item.init_lng) return;

      let status = getStatus(item.status);

      if (moment(item.date).isSame(moment(today), 'date')) {
        // Calcula el tiempo de retraso verificando los minutos de diferencia entre el horario establecido y la fecha actual
        const startMinutesDiff = moment(item.order_cleaner.order.time_starts_at, 'HH:mm:ss').diff(moment(item.started_at ?? undefined), 'minutes');
        if (startMinutesDiff < 0 && item.status === Constants.ORDER.STATUS.PENDING) {
          status = getStatus(DELAY_STATUS);
        }
      }

      let info = `${ item?.order_cleaner?.order?.store?.number || '' } - ${ item?.order_cleaner?.order?.store?.name || '' }`;
      info += `<br/>${ item?.order_cleaner?.cleaner?.person?.fullName || '' }`;
      info += `<br/>Teléfono: ${ item?.order_cleaner?.cleaner?.person?.phone || '' }`;

      const infowindow = new google.maps.InfoWindow({
        content: `<p style="font-size: 14px; font-weight: 500;">${ info }</p>`,
      });

      const pinSvg = parser.parseFromString(pinSvgString(item.id, status.hex), 'image/svg+xml').documentElement;
      const marker = new google.maps.marker.AdvancedMarkerElement({
        map: mapRef.current,
        position: new google.maps.LatLng(parseFloat(item.init_lat), parseFloat(item.init_lng)),
        title: item?.order_cleaner?.cleaner?.person?.fullName ?? '',
        draggable: false,
        content: pinSvg,
      });

      marker.addListener("click", () => {
        console.log('ACTIVANDO EL PIN')
        infowindow.open(mapRef.current, marker);
      });

      markers.push(marker);
      bounds.extend(marker.position);
      mapRef.current.fitBounds(bounds);
    });

    setMapMarkers(markers);
  }, [data]);

  return (
    <div id="monitor">
      <div className="grid grid-cols-1 md:grid-cols-6">
        <div className="col-span-2">
          <div className="md:w-[90%]">
            <div className="flex items-center gap-4 mb-4">
              <Input
                classNames={{
                  inputWrapper: 'border-1 h-10 bg-white',
                }}
                label="Buscar"
                labelPlacement="outside"
                startContent={<IconSearch />}
                isClearable
                placeholder="Nombre"
                onClear={() => onChange('', 'search')}
                variant="bordered"
                value={form.search}
                onValueChange={v => onChange(v, 'search')}
              />

              <Input
                type="date"
                classNames={{
                  base: 'w-full sm:max-w-[9rem]',
                  inputWrapper: 'border-1 h-10 bg-white',
                  input: `pr-0 text-${!!form.date ? '[]':'foreground-400'}`,
                }}
                label="Fecha"
                labelPlacement="outside"
                placeholder=" "
                variant="bordered"
                value={form.date}
                onValueChange={v => onChange(v, 'date')}
              />
            </div>

            <div className="container-list-users">
              {data.map((item, itemIdx) => {
                let status = getStatus(item.status);

                if (moment(item.date).isSame(moment(today), 'date')) {
                  // Calcula el tiempo de retraso verificando los minutos de diferencia entre el horario establecido y la fecha actual
                  const startMinutesDiff = moment(item.order_cleaner.order.time_starts_at, 'HH:mm:ss').diff(moment(item.started_at ?? undefined), 'minutes');
                  if (startMinutesDiff < 0) {
                    if (item.status === Constants.ORDER.STATUS.PENDING) {
                      status = getStatus(DELAY_STATUS);
                    }
                  }
                }

                return (
                  <div key={itemIdx} className="item" onClick={() => selectItem(item)}>
                    <div className="container-text">
                      <p className="name truncate">
                      { item?.order_cleaner?.order?.store?.number } - { item?.order_cleaner?.order?.store?.name }
                      </p>
                    </div>
                    <div className={classNames('container-status', status.color)}>
                      <p>{ status.label || item.statusText }</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div id="map" />
        </div>
      </div>
    </div>
  );
}

const useFetchTable = () => {
  const initialFilters = {
    search: '',
    date: moment().format('YYYY-MM-DD'),
    isPaginated: false,
  };

  const initialPagination = {
    page: 1,
    pages: 1,
    total: 0,
    perPage: Constants.PER_PAGE,
  };

  const [filters, setFilters] = useState(initialFilters);
  const [canFetch, setCanFetch] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);

  const debounceTime = 500;
  const debounce = useRef();

  const fetchData = async () => {
    if (!canFetch) return;
    setCanFetch(false);

    try {
      const response = await MonitorService.get(filters);
      const { data, ...rest } = response;

      setData(data);
      setPagination(rest);
      setCanFetch(true);

    } catch (error) {
      setData([]);
      onError(String(error));
    }
  }

  const canResetFilter = () => {
    const { page, perPage, ...initial } = initialFilters;
    const { page: _, perPage: __, ...current } = filters;
    const initFilter = JSON.stringify(initial);
    const currFilter = JSON.stringify(current);
    return initFilter !== currFilter;
  }

  const onError = (msg) => toast.error(msg);

  const filterBy = (value, target) => {
    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      setCanFetch(true);
      setFilters({ ...filters, page: 1, [target]: value });
    }, debounceTime);
  }

  const getCurrentPagination = () => {
    // Truco para obtener el estado actualizado (pagination es mantenida con el estado actual por el componente Pagination)
    let pag;
    setPagination(s => {
      pag = s;
      return s;
    });
    return pag;
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setFilters(initialFilters);
    else fetchData();
  }

  const changePerPage = (perPage) => {
    setCanFetch(true);
    setFilters({ ...filters, perPage });
  }

  const goToPage = (page) => {
    const pagination = getCurrentPagination();
    if (page >= 1 && page <= pagination.pages && page !== pagination.page) {
      setPagination({ ...pagination, page });
    }
  }

  useEffect(() => {
    fetchData();
  }, [filters]);

  return {
    filterBy,
    canResetFilter: canResetFilter(),
    data,
    // dataPaginated: data.slice((pagination.page - 1) * pagination.perPage, pagination.page * pagination.perPage),
    reload,
    isLoading: !canFetch,
    goToPage,
    pagination,
    changePerPage
  }
}

export default Monitor;