import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { classNames as cn, formatAmount } from '../utils';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 12, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ label, placeholder, value, onValueChange, classNames, ...inputProps }) => {
  const currencyMask = createNumberMask(defaultMaskOptions);
  const [isFocused, setIsFocused] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const onChange = (newValue) => {
    const amount = newValue === "" ? "" : +String(newValue).replace(/\./g, '').replace(',', '.');
    if (onValueChange) onValueChange(amount);
  };

  return (
    <div
      className="group flex flex-col w-full group relative justify-end data-[has-label=true]:mt-[calc(theme(fontSize.small)_+_10px)] is-filled"
      data-slot="base"
      data-has-elements="true"
      data-filled={String(!!value)}
      data-filled-within={String(!!placeholder)}
      data-has-label={String(!!label)}
      data-has-value={String(!!value)}
      data-hover={String(isHover)}
      data-focus={String(isFocused)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div data-slot="main-wrapper" className="h-full flex flex-col">
        <div
          data-slot="input-wrapper"
          className={cn('relative w-full inline-flex tap-highlight-transparent flex-row items-center shadow-sm px-3 gap-3 border-default-200 data-[hover=true]:border-default-400 group-data-[focus=true]:border-default-foreground min-h-unit-10 rounded-medium transition-background !duration-150 transition-colors motion-reduce:transition-none border-1 h-12 is-filled', classNames?.inputWrapper)}
          data-hover={String(isHover)}
          data-focus={String(isFocused)}
          style={{ cursor: 'text' }}
        >
          {!!label && (
            <label data-slot="label" className="absolute pointer-events-none origin-top-left subpixel-antialiased text-foreground-500 will-change-auto !duration-200 !ease-out motion-reduce:transition-none transition-[transform,color,left,opacity] group-data-[filled-within=true]:text-foreground group-data-[filled-within=true]:pointer-events-auto pb-0 z-20 top-1/2 -translate-y-1/2 group-data-[filled-within=true]:left-0 left-3 text-small group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.small)/2_+_20px)] pe-2 max-w-full text-ellipsis overflow-hidden flex flex-col leading-4">
              {label}
            </label>
          )}
          <div data-slot="inner-wrapper" className="inline-flex w-full items-center h-full box-border">
            <MaskedInput
              //value={value ? formatAmount(value) : ""}
              value={value === '' ? '' : formatAmount(value)}
              placeholder={placeholder}
              {...inputProps}
              onChange={(e) => onChange(e.target.value)}
              className={cn('w-full font-normal bg-transparent !outline-none placeholder:text-foreground-500 focus-visible:outline-none data-[has-start-content=true]:ps-1.5 data-[has-end-content=true]:pe-1.5 text-small h-full is-filled', inputProps?.className)}
              inputMode="numeric"
              mask={currencyMask}
              data-filled={String(!!value)}
              data-filled-within={String(!!placeholder)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyInput;