import { fetchWrapper } from '../utils';

const baseUrl = process.env.REACT_APP_BASE_API + 'admin/payroll';

export async function get(params) {
  const url = `${baseUrl}/get`;
  return await fetchWrapper.post(url, params);
}

export async function getPayroll(params) {
  const url = `${baseUrl}/get-payroll`;
  return await fetchWrapper.post(url, params);
}

export async function getPayrollSupervisor(params) {
  const url = `${baseUrl}/get-payroll-supervisor`;
  return await fetchWrapper.post(url, params);
}

export async function getPayments(params) {
  const url = `${baseUrl}/payments`;
  return await fetchWrapper.post(url, params);
}

export async function downloadPayments(params) {
  const url = `${baseUrl}/payments/download`;
  return await fetchWrapper.post(url, params);
}

export async function generateReceipt(params) {
  const url = `${baseUrl}/payments/receipt`;
  return await fetchWrapper.post(url, params);
}

export async function createPayment(params) {
  const url = `${baseUrl}/newpayment`;
  return await fetchWrapper.post(url, params);
}

export async function getBanks() {
  const url = `${baseUrl}/banks`;
  return await fetchWrapper.post(url);
}